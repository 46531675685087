import React, { PureComponent } from "react";
import {
	Nav,
	Row,
	Col,
	Button,
	FormControl,
	Container,
	OverlayTrigger,
	Tooltip,
	Card,
	Image,
} from "react-bootstrap";
import {
	AiFillEye,
	AiFillEyeInvisible,
	AiOutlineQuestionCircle,
} from "react-icons/ai";
import { NavLink } from "react-router-dom";
import Preregistration from "../Preregistration";
import logo from "../../images/logo_fiui_full.png";
const NAME_REGEX = /^[a-zA-Z\s]*$/;
const EMAIL_REGEX =
	/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/;

class Register extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			firstNameError: false,
			lastNameError: false,
			emailError: false,
			passwordError: false,
			confirmPasswordError: false,
			submissionError: true,
			showPassword: true,
			showConfirmPassword: true,
			params: {
				first: "",
				last: "",
				bank_name: "",
				bank_code: "",
				email: "",
				password: "",
				confirm_password: "",
				role: "",
			},
			roleDescription: "",
			roleList: {
				data: [],
				loaded: false,
				loading: false,
				error: "",
			},
		};
	}

	componentDidMount() {
		this.props.getRoleListRequest();
	}

	componentWillReceiveProps(nextProps) {
		if (this.props !== nextProps) {
			this.setState({
				roleList: nextProps.roleList,
			});
		}
	}

	handleOnChange = (e) => {
		const { name, value } = e.target;

		if (name === "role") {
			const { data } = this.state.roleList;
			for (let i = 0; i < data.length; i++) {
				if (value === "") {
					this.setState({
						...this.state,
						roleDescription: "",
					});
				}

				if (data[i]._id === value) {
					this.setState({
						...this.state,
						roleDescription: data[i].description,
					});
				}
			}
		}

		this.setState({
			...this.state,
			submissionError: false,
			params: {
				...this.state.params,
				[name]: value,
			},
		});
	};

	handleRegister = () => {
		this.setState({
			...this.state,
			submissionError: true,
		});
		this.props.registerRequest(this.state.params);
	};

	/****************************************************************************/

	renderRole = (role) => {
		// role.name
		return <option value={[role._id, role.name]}>{role.name}</option>;
	};

	renderDescriptionIcon = () => {
		const { roleDescription } = this.state;

		return (
			<OverlayTrigger
				key="role-description"
				placement="top"
				overlay={<Tooltip>{roleDescription}</Tooltip>}
			>
				<AiOutlineQuestionCircle className="individual-form-icon password-format-icon" />
			</OverlayTrigger>
		);
	};

	renderLogo = () => (
		<div className="login-logo-row">
			<div className="login-logo-box">
				<img src={logo} className="login-logo" />
			</div>
		</div>
	);

	checkNameFormat = (nameTypeError, nameType) => {
		this.setState(
			{
				...this.state,
				[nameTypeError]: !NAME_REGEX.test(this.state.params[nameType]),
			},
			() => console.log(this.state)
		);
	};

	checkEmailFormat = () => {
		this.setState(
			{
				...this.state,
				emailError: !EMAIL_REGEX.test(this.state.params.email),
			},
			() => console.log(this.state)
		);
	};

	checkPasswordFormat = () => {
		// password must be at least 8 characters long and contain at least 1 number and 1 letter
		let passwordError = false;
		if (
			!/\d/.test(this.state.params.password) ||
			!/[a-zA-Z]/.test(this.state.params.password) ||
			this.state.params.password.length < 8
		) {
			passwordError = true;
		}

		this.setState({
			...this.state,
			passwordError: passwordError,
		});
	};

	renderPasswordFormatIcon = () => {
		return (
			<OverlayTrigger
				key="role-description"
				placement="top"
				overlay={
					<Tooltip>
						Your password must be a minimum of eight characters long
						and contain at least one letter and one number.
					</Tooltip>
				}
			>
				<AiOutlineQuestionCircle className="individual-form-icon password-format-icon" />
			</OverlayTrigger>
		);
	};

	togglePsVisibility = (confirm = "") => {
		const passwordBox = document.getElementsByName(
			confirm === "confirm" ? "confirm_password" : "password"
		);

		passwordBox.forEach(
			(psw) => (psw.type = psw.type === "text" ? "password" : "text")
		);

		const prop =
			confirm === "confirm" ? "showConfirmPassword" : "showPassword";

		this.setState({
			...this.state,
			[prop]: passwordBox[0].type === "password",
		});
	};

	confirmPassword = () => {
		let confirmPasswordError = false;

		if (this.state.params.password !== this.state.params.confirm_password)
			confirmPasswordError = true;

		this.setState({
			...this.state,
			confirmPasswordError,
		});
	};

	renderRegistration = () => {
		const {
			roleList,
			roleDescription,
			params,
			passwordError,
			emailError,
			submissionError,
		} = this.state;

		const {
			first,
			last,
			bank_name,
			bank_code,
			email,
			password,
			confirm_password,
			role,
		} = params;

		const disabled =
			(first && last && email && password && role && confirm_password
				? false
				: true) ||
			passwordError ||
			emailError ||
			confirm_password !== password ||
			submissionError;

		let selectOptions;
		if (roleList.loaded) {
			selectOptions = roleList.data.map((role) => this.renderRole(role));
		} else {
			selectOptions = "";
		}

		let descriptionIcon;
		if (roleDescription) {
			descriptionIcon = this.renderDescriptionIcon();
		}

		// Disable double click selection on password icons.
		const elem = document.getElementsByClassName("password-icon");

		Array.from(elem).forEach((el) => {
			el.addEventListener("mousedown", (e) => {
				if (e.detail > 1) {
					e.preventDefault();
				}
			});
		});

		return (
			<Container fluid className="login-container">
				<Row noGutters className="login-row">
					<Col sm={8} md={6} lg={4} xl={4}>
						<Card className="login-box">
							<Row noGutters>
								<Col className="login-content-col">
									<Row noGutters className="login-header">
										<Image
											fluid
											src={logo}
											className="login-logo"
										/>
									</Row>

									<Row
										noGutters
										className="login-box-padding login-formcontrol-row"
									>
										<Col className="login-box-col">
											<Row
												noGutters
												className="login-welcome"
											>
												<Col>
													<h1>Welcome</h1>
													<p>
														Register your account to
														continue
													</p>
												</Col>
											</Row>

											<Row
												noGutters
												className="login-formcontrol-container"
											>
												<div className="login-formcontrol-box"
													id={
														this.state.firstNameError &&
														"formControl-error"
													}>
													<div className="login-formcontrol-text">
														First Name
													</div>
													<FormControl
														size="sm"
														name="first"
														value={first}
														onBlur={
															() => this.checkNameFormat("firstNameError", "first")
														}
														onChange={
															this.handleOnChange
														}
														className="login-formcontrol"
													/>
													{this.state.firstNameError && (
														<div className="system-form-control-error-text">
															Invalid first name format
														</div>
													)}
												</div>
											</Row>

											<Row
												noGutters
												className="login-formcontrol-container"
											>
												<div className="login-formcontrol-box"
													id={
														this.state.lastNameError &&
														"formControl-error"
													}>
													<div className="login-formcontrol-text">
														Last Name
													</div>
													<FormControl
														size="sm"
														name="last"
														value={last}
														onBlur={
															() => this.checkNameFormat("lastNameError", "last")
														}
														onChange={
															this.handleOnChange
														}
														className="login-formcontrol"
													/>
													{this.state.lastNameError && (
														<div className="system-form-control-error-text">
															Invalid last name format
														</div>
													)}
												</div>
											</Row>
											<Row
												noGutters
												className="login-formcontrol-container"
											>
												<div className="login-formcontrol-box">
													<div className="login-formcontrol-text">
														Bank Name
													</div>
													<FormControl
														size="sm"
														name="bank_name"
														value={bank_name}
														onChange={
															this.handleOnChange
														}
														className="login-formcontrol"
													/>
												</div>
											</Row>
											<Row
												noGutters
												className="login-formcontrol-container"
											>
												<div className="login-formcontrol-box">
													<div className="login-formcontrol-text">
														Registration Code
													</div>
													<FormControl
														size="sm"
														name="bank_code"
														value={bank_code}
														onChange={
															this.handleOnChange
														}
														className="login-formcontrol"
													/>
												</div>
											</Row>
											<Row
												noGutters
												className="login-formcontrol-container"
											>
												<div
													className="login-formcontrol-box"
													id={
														this.state.emailError &&
														"formControl-error"
													}
												>
													<div className="login-formcontrol-text">
														Email
													</div>
													<FormControl
														size="sm"
														name="email"
														value={email}
														onBlur={
															this
																.checkEmailFormat
														}
														onChange={
															this.handleOnChange
														}
														className="login-formcontrol"
													/>
													{this.state.emailError && (
														<div className="system-form-control-error-text">
															Invalid email format
														</div>
													)}
												</div>
											</Row>

											<Row
												noGutters
												className="login-formcontrol-container"
											>
												<div
													className="login-formcontrol-box"
													id={
														this.state
															.passwordError &&
														"formControl-error"
													}
												>
													<div className="login-formcontrol-text">
														Password
													</div>
													<FormControl
														size="sm"
														name="password"
														type="password"
														value={password}
														onChange={
															this.handleOnChange
														}
														onBlur={
															this
																.checkPasswordFormat
														}
														className="login-formcontrol"
													/>
													<div className="password-icon">
														{!this.state
															.showPassword ? (
															<AiFillEyeInvisible
																onClick={
																	this
																		.togglePsVisibility
																}
															/>
														) : (
															<AiFillEye
																onClick={
																	this
																		.togglePsVisibility
																}
															/>
														)}
													</div>

													{this.state
														.passwordError && (
															<div className="system-form-control-error-text">
																Your password must
																be a minimum of
																eight characters
																long and contain at
																least one letter and
																one number.
															</div>
														)}
													{this.renderPasswordFormatIcon()}
												</div>
											</Row>
											<Row
												noGutters
												className="login-formcontrol-container"
											>
												<div
													className="login-formcontrol-box"
													id={
														this.state
															.passwordError &&
														"formControl-error"
													}
												>
													<div className="login-formcontrol-text">
														Confirm Password
													</div>
													<FormControl
														size="sm"
														name="confirm_password"
														type="password"
														value={confirm_password}
														onChange={
															this.handleOnChange
														}
														onBlur={
															this.confirmPassword
														}
														className="login-formcontrol"
													/>
													<div className="password-icon">
														{!this.state
															.showConfirmPassword ? (
															<AiFillEyeInvisible
																onClick={() =>
																	this.togglePsVisibility(
																		"confirm"
																	)
																}
															/>
														) : (
															<AiFillEye
																onClick={() =>
																	this.togglePsVisibility(
																		"confirm"
																	)
																}
															/>
														)}
													</div>

													{this.state
														.confirmPasswordError && (
															<div className="system-form-control-error-text">
																Passwords must
																match!
															</div>
														)}
												</div>
											</Row>
											<Row
												noGutters
												className="login-formcontrol-container"
											>
												<div className="login-formcontrol-box">
													<div className="login-formcontrol-text">
														Select Product
													</div>
													<FormControl
														size="sm"
														as="select"
														name="role"
														value={role}
														onChange={
															this.handleOnChange
														}
														className="login-formcontrol"
													>
														<option value=""
															disabled={true}
															selected={true}>
															Select an option...
														</option>
														{selectOptions}
													</FormControl>
													{descriptionIcon}
												</div>
											</Row>

											<Row
												noGutters
												className="login-button-row"
											>
												<Button
													size="sm"
													disabled={disabled}
													onClick={
														this.handleRegister
													}
													className="teal-button"
												>
													REGISTER
												</Button>
											</Row>

											<div className="register-navlink">
												Already have an account?
												<NavLink to="/login">
													Log in
												</NavLink>
											</div>
										</Col>
									</Row>
								</Col>
							</Row>
						</Card>
					</Col>
				</Row>
			</Container>
		);
	};

	renderCannotRegister = () => {
		return (
			<Container fluid className="login-container">
				<Row noGutters className="login-row">
					<Col sm={8} md={6} lg={4} xl={4}>
						<Card className="login-box">
							<Row noGutters>
								<Col className="login-content-col">
									<Row noGutters className="login-header">
										<Image
											fluid
											src={logo}
											className="login-logo"
										/>
									</Row>

									<Row
										noGutters
										className="login-box-padding login-formcontrol-row"
									>
										<Col className="login-box-col">
											<Row
												noGutters
												className="login-welcome"
											>
												<Col>
													<h1>Welcome</h1>
													<p>
														You are not eligible to
														register
													</p>
												</Col>
											</Row>

											<div className="register-navlink">
												Already have an account?
												<NavLink to="/login">
													Log in
												</NavLink>
											</div>
										</Col>
									</Row>
								</Col>
							</Row>
						</Card>
					</Col>
				</Row>
			</Container>
		);
	};

	// renderPreregistration = () => {
	//   const { preregistration } = this.props
	//
	//   let content
	//   if (preregistration === 'pass') {
	//     content = this.renderRegistration()
	//   } else if (preregistration === 'fail') {
	//     content = this.renderCannotRegister()
	//   } else {
	//     content = <Preregistration />
	//   }
	//
	//   return content
	// }

	render() {
		return this.renderRegistration();
		// return this.renderPreregistration()
	}
}

export default Register;
