import React, { PureComponent } from "react";
import {
	Row,
	Col,
	Container,
	FormControl,
	Button,
	Table,
	Spinner,
	Modal,
	Accordion,
	Dropdown,
	OverlayTrigger,
	Tooltip,
} from "react-bootstrap";
import { NavLink } from "react-router-dom";
import {
	AiOutlineEdit,
	AiOutlineReload,
	AiOutlineFilePdf,
} from "react-icons/ai";
import ReactHtmlParser from "react-html-parser";
import Pagination from "../../Pagination/Pagination";
import html2pdf from "html2pdf.js";

const pages = document.getElementsByClassName("pages");

class UserManagement extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			users: {
				data: [],
				loading: false,
				loaded: false,
				error: "",
			},
			roles: {
				data: [],
				loading: false,
				loaded: false,
				error: "",
			},
			params: {
				email: "",
				role: "",
				status: "",
				offset: 0,
			},
			userReview: {
				data: {},
				loading: false,
				loaded: false,
				error: "",
			},
			showUserReviewModal: false,
			showConfirmDeleteModal: false,
			showUserInfoModal: false,
			currentPage: 0,
			userDataForPdf: {
				data: [],
				loading: false,
				loaded: false,
			},
			generatedPdf: {
				data: {},
				loading: false,
				loaded: false,
			},
			showPdfModal: false,
			showPdfViewModal: false,
			editUserParams: {},
			editUser: {
				loading: false,
			},
			pdfType: "table",
			oldStatus: "",
		};
	}

	componentDidMount() {
		this.props.userManagementSearchUsersRequest();
		this.props.getListOfRolesRequest();
		this.props.resetReviewUser();
		this.props.clearPdf();
		this.props.editUserReset();
	}

	componentWillReceiveProps(nextProps) {
		if (this.props !== nextProps) {
			this.setState({
				users: nextProps.userManagementUsers,
				roles: nextProps.userManagementRoles,
				userReview: nextProps.userManagementUserReview,
				userDataForPdf: nextProps.userDataForPdf,
				editUser: nextProps.editUser,
			});
		}
	}

	handleSearch = (e) => {
		const { value, name } = e.target;
		this.setState(
			{
				params: {
					...this.state.params,
					[name]: value,
				},
			},
			() => this.props.userManagementSearchUsersRequest(this.state.params)
		);
	};

	handleGetUserToReview = (id) => {
		this.handleShowUserReviewModal();
		this.props.reviewUserRequest(id);
	};

	handleClearReviewUser = () => {
		this.props.resetReviewUser();
	};

	handleDeleteUser = () => {
		const { _id } = this.state.userReview.data.docs[0];
		this.props.deleteUserRequest(_id);
		this.setState({
			showUserReviewModal: false,
			showConfirmDeleteModal: false,
		});
	};

	handleShowUserReviewModal = () => {
		this.setState({
			showUserReviewModal: true,
		});
	};

	handleHideuserReviewModal = () => {
		this.handleSaveChanges();
		this.setState(
			{
				showUserReviewModal: false,
				oldStatus: "",
			},
			() => this.props.clearPdf()
		);
	};

	handleUpdateUserStatus = (e) => {
		const newData = this.state.userReview.data.docs[0];

		if (newData.status !== e.target.value) {
			this.setState({
				oldStatus: newData.status,
			});
		}

		newData.status = e.target.value;

		this.setState({
			userReview: {
				...this.state.userReview,
			},
		});
	};

	handleEditUser = (e) => {
		const userReviewDocs = this.state.userReview.data.docs;

		if (e.target.name == "first") {
			userReviewDocs[0].first_name = e.target.value;
		} else if (e.target.name == "last") {
			userReviewDocs[0].last_name = e.target.value;
		} else if (e.target.name == "secondary_account") {
			userReviewDocs[0].secondary_account = e.target.value;
		} else if (e.target.name == "tax_code") {
			userReviewDocs[0].tax_code = e.target.value;
		} else if (e.target.name === "bta_tier") {
			userReviewDocs[0].bta_tier = e.target.value;
		} else if (e.target.name === "bta_risk_score") {
			userReviewDocs[0].bta_risk_score = e.target.value;
		}

		this.setState({
			userReview: {
				...this.state.userReview,
			},
		});
	};

	handleSaveChanges = () => {
		const {
			status,
			_id,
			first_name,
			last_name,
			secondary_account,
			bta_tier,
			bta_risk_score,
		} = this.state.userReview.data.docs[0];
		const oldStatus = this.state.oldStatus;

		const editParams = {
			_id,
			first: first_name,
			last: last_name,
			secondary: secondary_account,
			status: status,
			bta_tier,
			bta_risk_score,
		};

		this.props.editUserRequest(editParams, oldStatus, this.state.params);

		this.setState({
			oldStatus: "",
		});
	};

	handleResetQueries = () => {
		this.setState(
			{
				params: {
					email: "",
					role: "",
					status: "",
					offset: 0,
				},
				currentPage: 0,
			},
			() => this.props.userManagementSearchUsersRequest()
		);
	};

	handleClickPage = (number) => {
		this.setState(
			{
				currentPage: number.selected,
				params: {
					...this.state.params,
					offset: number.selected * 10,
				},
			},
			() => this.props.userManagementSearchUsersRequest(this.state.params)
		);
	};

	handleShowConfirmDeleteModal = () => {
		this.setState({
			showUserReviewModal: false,
			showConfirmDeleteModal: true,
		});
	};

	handleHideConfirmDeleteModal = () => {
		this.setState({
			showConfirmDeleteModal: false,
			showUserReviewModal: true,
		});
	};

	handleHidePdfModal = () => {
		this.setState({
			showPdfModal: false,
		});
	};

	handleShowPdfModal = () => {
		this.setState({
			showPdfModal: true,
		});
	};

	formatDate = (dateInput) => {
		let date = new Date(dateInput);
		const month = date.toLocaleString("en-us", { month: "short" });
		const day = date.getDate();
		const year = date.getFullYear();
		return month + " " + day + ", " + year;
	};

	formatDateWithTime = (dateInput) => {
		return dateInput.toLocaleString();
	};

	convertImageToBase64 = (img, doc, cellData) => {
		return new Promise((resolve, reject) => {
			let tempImg = img.split(" ");
			let image;
			const options = {
				method: "GET",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/x-www-form-urlencoded",
				},
				credentials: "include",
			};
			fetch(tempImg[1], options).then((response) =>
				response.arrayBuffer().then((buffer) => {
					var base64regex =
						/^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;

					if (!base64regex.test(img)) {
						// if the image is not already in base64, convert it to base64
						var base64Flag = "data:image/jpeg;base64,";
						var imageStr = this.arrayBufferToBase64(buffer);
						image = base64Flag + imageStr;
					} else {
						image = img;
					}

					doc.addImage(
						image,
						"PNG",
						cellData.cell.x,
						cellData.cell.y,
						100,
						100
					);
					resolve("resolve");
				})
			);
		});
	};

	arrayBufferToBase64 = (buffer) => {
		var binary = "";
		var bytes = [].slice.call(new Uint8Array(buffer));

		bytes.forEach((b) => (binary += String.fromCharCode(b)));

		return window.btoa(binary);
	};

	handleGetUserDataForPdf = (userid) => {
		this.props.exportPdfRequest(userid);
	};

	toggleUserInfoModal = () => {
		this.setState({
			showUserInfoModal: !this.state.showUserInfoModal,
			showUserReviewModal: !this.state.showUserReviewModal,
		});
	};

	togglePdfType = (type) => {
		this.setState({
			pdfType: type,
		});
	};

	generatePdfNew = () => {
		const { data, user_email, loading, loaded } = this.state.userDataForPdf;
		const options = {
			margin: 0.25,
			filename: `User Onboarding Report for ${this.state.userDataForPdf.user_email}.pdf`,
			image: { type: "jpeg", quality: 1 },
			jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
			pagebreak: { mode: "avoid-all" },
		};

		html2pdf().set(options).from(pages[0]).save();
	};

	//////////////////////////////////////////////////////////////////////////////
	//////////////////////////////////////////////////////////////////////////////
	//////////////////////////////////////////////////////////////////////////////

	renderRolesOptions = (role) => {
		const { _id, name, description } = role;
		if (name !== "Admin")
			return (
				<option key={_id} value={_id}>
					{name}
				</option>
			);
	};

	renderSearchBar = () => {
		const { params, roles } = this.state;

		let rolesOptions;
		if (roles.loaded) {
			rolesOptions = roles.data.map((role) =>
				this.renderRolesOptions(role)
			);
		}

		return (
			<Row noGutters className="submissions-search-bar">
				<Col>
					<label className="submission-form-control-label">
						Email:
					</label>
					<FormControl
						size="sm"
						value={params.email}
						name="email"
						onChange={this.handleSearch}
						placeholder="Search for users..."
						className="submission-form-control"
					/>
				</Col>

				<Col>
					<label className="submission-form-control-label">
						Role:
					</label>
					<FormControl
						size="sm"
						as="select"
						name="role"
						value={params.role}
						onChange={this.handleSearch}
						className="submission-form-control"
					>
						<option value="">Select a role...</option>
						{rolesOptions}
					</FormControl>
				</Col>

				<Col>
					<label className="submission-form-control-label">
						Status:
					</label>
					<FormControl
						size="sm"
						as="select"
						name="status"
						value={params.status}
						onChange={this.handleSearch}
						className="submission-form-control"
					>
						<option value="">Status...</option>
						<option value="approved">Approved</option>
						<option value="rejected">Rejected</option>
						<option value="open">Open</option>
					</FormControl>
				</Col>
			</Row>
		);
	};

	renderTable = () => {
		const { users } = this.state;

		let content;
		if (users.loaded) {
			content = users.data.docs.map((user) =>
				this.renderIndividualUser(user)
			);
		} else if (users.loading) {
			content = (
				<tr>
					<td colSpan="4">{this.renderLoading()}</td>
				</tr>
			);
		}

		return (
			<Table bordered className="submissions-table">
				<thead>
					<tr>
						<th>Email</th>
						<th>Role</th>
						<th>Status</th>
					</tr>
				</thead>

				<tbody>{content}</tbody>
			</Table>
		);
	};

	renderIndividualUser = (user) => {
		const { status, _id, email, role } = user;

		return (
			<tr key={_id}>
				<td>{email}</td>
				<td>{role ? role.name : "No role name"}</td>
				<td>
					<Row noGutters className="individual-form-status-row">
						{status}

						<div>
							<AiOutlineEdit
								onClick={() => this.handleGetUserToReview(_id)}
								className="individual-form-icon"
							/>
						</div>
					</Row>
				</td>
			</tr>
		);
	};

	renderSavingUserEdit = () => {
		let content;
		if (this.state.changesMade) {
			content = (
				<div className="saving-edit-user">
					You have unsaved changes.
				</div>
			);
		}

		return content;
	};

	renderUserInfoModal = () => {
		const { data, user_email, loading, loaded } = this.state.userDataForPdf;

		return (
			<Modal
				centered
				className="common-modal submission-modal pdf-modal"
				show={this.state.showUserInfoModal}
				onHide={this.toggleUserInfoModal}
			>
				<Modal.Header closeButton>
					<div className="toggle-for-user-info-modal">
						<Button
							onClick={() => this.togglePdfType("table")}
							className={
								this.state.pdfType == "table"
									? "teal-button xs-button"
									: "transparent-teal-button xs-button"
							}
						>
							Table
						</Button>
						<Button
							onClick={() => this.togglePdfType("document")}
							className={
								this.state.pdfType == "document"
									? "teal-button xs-button"
									: "transparent-teal-button xs-button"
							}
						>
							Document
						</Button>
					</div>
				</Modal.Header>

				<Modal.Body>
					<div className="pdf-border-box">
						<Button
							onClick={this.generatePdfNew}
							size="sm"
							className="floating-save-button transparent-teal-button"
						>
							Download PDF
						</Button>
						<div className="pages" id={this.state.pdfType}>
							<div className="pdf-header-content">
								<div className="pdf-header">
									User Onboarding Report for {user_email}
								</div>
								<div className="pdf-date-generated">
									Generated by
									<b className="username">
										{this.props.account.email}
									</b>
									on {this.formatDateWithTime(new Date())}
								</div>
								<div className="pdf-required">
									* indicates a required field
								</div>
							</div>

							<div id="pdf-page">
								{loaded &&
									data.map((item) =>
										this.renderUserInfoItem(item)
									)}
							</div>
						</div>
					</div>
				</Modal.Body>

				<Modal.Footer>
					<Button
						onClick={this.generatePdfNew}
						className="teal-button"
						size="sm"
					>
						Download PDF
					</Button>
				</Modal.Footer>
			</Modal>
		);
	};

	renderUserInfoItem = (item) => {
		function renderField(field) {
			let content;
			let fieldName;
			let subcontent;
			fieldName = (
				<div className={"fieldname" + (field.fieldType == "checkbox" ? " fieldNameCheckbox" : "")}>
					{field.fieldName}
					{field.required && "*"}
				</div>
			);
			if (
				field.fieldType == "e-signature" ||
				field.fieldType == "image"
			) {
				content = <img src={field.value} className="image" />;
			} else if (field.fieldType == "branch") {
				const branch = field.subfields[field.value];
				if (branch && branch.data && branch.data.length > 0) {
					subcontent = branch.data.map((d) => {
						return (
							<div key={Math.random()} className="branch field">
								<div className="fieldname">
									{d.fieldName}
									{d.required && "*"}
								</div>
								<div className="answer">{d.value || "-"}</div>
							</div>
						);
					});
				}
				content = (
					<div className="answer">
						{branch ? branch.name : "Not submitted"}
					</div>
				);
			} else if (field.fieldType == "checkbox") {
				const checkboxSubFields = field.subfields;
				if (checkboxSubFields && checkboxSubFields.length > 0) {
					subcontent = checkboxSubFields.map((d) => {
						return (
							<div key={Math.random()} className="option field">
								<div className="fieldname">
									{d.fieldName}
									{d.required && "*"}
								</div>
								<div className="answer">{d.value || "-"}</div>
							</div>
						);
					});
				}
				content = null;
			} else if (field.fieldType == "date") {
				content = (
					<div className="answer">
						{new Date(field.value).toLocaleDateString() || "-"}
					</div>
				);
			} else if (field.fieldType == "idmplugin") {
				if (field && field.data && field.data.length > 0) {
					subcontent = field.data.map((d) => {
						let innerContent;
						if (d.type == "file") {
							if (d.data) {
								innerContent = (
									<img className="image" src={d.data} />
								);
							} else {
								innerContent = (
									<div className="answer">{"-"}</div>
								);
							}
						} else {
							innerContent = (
								<div className="answer">{d.data || "-"}</div>
							);
						}

						return (
							<div key={Math.random()} className="plugin field">
								<div className="fieldname">{d.label}</div>
								{innerContent}
							</div>
						);
					});
				}

				fieldName = "";
			} else if (field.fieldType == "document" && field.value) {
				content = (
					<a className="answer" target="_blank" href={field.value}>
						Link to document
					</a>
				);
			} else {
				content = <div className="answer">{field.value || "-"}</div>;
			}

			return (
				<>
					<div
						key={Math.random()}
						className="field"
						id={field.fieldType == "idmplugin" && "hidden"}
					>
						{fieldName}
						{content}
					</div>
					{subcontent}
				</>
			);
		}

		return (
			<div key={Math.random()} className="pdf-item">
				<div className="title">{item.name}</div>
				<div className="items">
					{item.fields.length > 0 &&
						item.fields.map((field) => renderField(field))}
				</div>
			</div>
		);
	};

	renderReviewUserModal = () => {
		const {
			showUserReviewModal,
			userReview,
			userDataForPdf,
			editSpecificUser,
		} = this.state;

		let body;
		if (userReview.loaded) {
			const {
				_id,
				first_name,
				last_name,
				email,
				role,
				emailConfirmed,
				status,
				riskScore,
				riskCategory,
				primetrust_id,
				secondary_account,
				bta_tier,
				bta_risk_score,
				tax_code,
			} = userReview.data.docs[0];
			let exportPdfButton;
			if (userDataForPdf.loading) {
				exportPdfButton = (
					<Button
						disabled
						className="teal-button export-button"
						size="sm"
					>
						<Spinner
							animation="border"
							className="in-button-spinner"
						/>{" "}
						Loading PDF...
					</Button>
				);
			} else if (userDataForPdf.loaded) {
				exportPdfButton = (
					<Button
						onClick={this.toggleUserInfoModal}
						size="sm"
						className="export-button teal-button"
					>
						View PDF
					</Button>
				);
			} else {
				exportPdfButton = (
					<Button
						onClick={() => this.handleGetUserDataForPdf(_id)}
						className="teal-button export-button"
						size="sm"
					>
						Generate PDF
					</Button>
				);
			}

			body = (
				<React.Fragment>
					<Modal.Body>
						<Row noGutters className="specific-submission-field">
							<Col>
								<label className="specific-submission-field-label">
									Id
								</label>

								<div className="specific-submission-content">
									{_id}
								</div>
							</Col>
						</Row>

						<Row noGutters className="specific-submission-field">
							<Col>
								<div className="specific-submission-editable-container">
									<label className="specific-submission-field-label">
										First Name
									</label>
									<div className="editable-field">
										(Editable)
									</div>
								</div>

								<FormControl
									className="specific-submission-content"
									size="sm"
									onChange={this.handleEditUser}
									name="first"
									value={first_name}
								/>
							</Col>
						</Row>

						<Row noGutters className="specific-submission-field">
							<Col>
								<div className="specific-submission-editable-container">
									<label className="specific-submission-field-label">
										Last Name
									</label>
									<div className="editable-field">
										(Editable)
									</div>
								</div>

								<FormControl
									className="specific-submission-content"
									size="sm"
									onChange={this.handleEditUser}
									name="last"
									value={last_name}
								/>
							</Col>
						</Row>

						<Row noGutters className="specific-submission-field">
							<Col>
								<label className="specific-submission-field-label">
									Email
								</label>

								<div className="specific-submission-content">
									{email}
								</div>
							</Col>
						</Row>

						<Row noGutters className="specific-submission-field">
							<Col>
								<label className="specific-submission-field-label">
									Role
								</label>

								<div className="specific-submission-content">
									{role ? role.name : "No role selected"}
								</div>
							</Col>
						</Row>

						<Row noGutters className="specific-submission-field">
							<Col>
								<label className="specific-submission-field-label">
									Confirmed Email?
								</label>

								<div className="specific-submission-content">
									{emailConfirmed ? "True" : "False"}
								</div>
							</Col>
						</Row>

						<Row noGutters className="specific-submission-field">
							<Col>
								<label className="specific-submission-field-label">
									Status
								</label>

								<div className="specific-submission-content">
									{status}
								</div>
							</Col>
						</Row>

						<Row noGutters className="specific-submission-field">
							<Col>
								<label className="specific-submission-field-label">
									Risk Category
								</label>

								<div className="specific-submission-content">
									{riskCategory || ""}
								</div>
							</Col>
						</Row>

						<Row noGutters className="specific-submission-field">
							<Col>
								<label className="specific-submission-field-label">
									Risk Score
								</label>

								<Accordion
									className="specific-submission-content"
									defaultActiveKey={""}
								>
									Total: {riskScore.total} -
									<Accordion.Toggle
										className="risk-score-accordion-toggle"
										eventKey="0"
									>
										View Report
									</Accordion.Toggle>
									<Accordion.Collapse
										className="risk-score-accordion-collapse"
										eventKey="0"
									>
										<Table
											bordered
											className="submissions-table"
										>
											<thead>
												<tr>
													<th>Field Name</th>
													<th>Value</th>
													<th>Assigned Score</th>
												</tr>
											</thead>

											<tbody>
												{riskScore.riskFields.map(
													(field) =>
														this.renderRiskFields(
															field
														)
												)}
											</tbody>
										</Table>
									</Accordion.Collapse>
								</Accordion>
							</Col>
						</Row>

						{primetrust_id && (
							<Row
								noGutters
								className="specific-submission-field"
							>
								<Col>
									<label className="specific-submission-field-label">
										Prime Trust ID
									</label>

									<div className="specific-submission-content">
										{primetrust_id}
									</div>
								</Col>
							</Row>
						)}

						<Row noGutters className="specific-submission-field">
							<Col>
								<div className="specific-submission-editable-container">
									<label className="specific-submission-field-label">
										Secondary Account
									</label>
									<div className="editable-field">
										(Editable)
									</div>
								</div>

								<FormControl
									className="specific-submission-content"
									size="sm"
									onChange={this.handleEditUser}
									name="secondary_account"
									value={secondary_account}
								/>
							</Col>
						</Row>
						<Row noGutters className="specific-submission-field">
							<Col>
								<div className="specific-submission-editable-container">
									<label className="specific-submission-field-label">
										BTA Tier
									</label>
									<div className="editable-field">
										(Editable)
									</div>
								</div>

								<FormControl
									className="specific-submission-content"
									size="sm"
									onChange={this.handleEditUser}
									name="bta_tier"
									value={bta_tier}
								/>
							</Col>
						</Row>
						<Row noGutters className="specific-submission-field">
							<Col>
								<div className="specific-submission-editable-container">
									<label className="specific-submission-field-label">
										BTA Risk Score
									</label>
									<div className="editable-field">
										(Editable)
									</div>
								</div>

								<FormControl
									className="specific-submission-content"
									size="sm"
									onChange={this.handleEditUser}
									name="bta_risk_score"
									value={bta_risk_score}
								/>
							</Col>
						</Row>
					</Modal.Body>

					<Modal.Footer>
						<Col className="submission-modal-column">
							<Row noGutters>
								<label>Set Status:</label>
								<Col>
									<FormControl
										className="submission-form-control"
										as="select"
										onChange={this.handleUpdateUserStatus}
										value={userReview.data.docs[0].status}
										size="sm"
									>
										<option value="" disabled={true}>
											Choose a status...
										</option>
										<option value="approved">
											Approved
										</option>
										<option value="rejected">
											Rejected
										</option>
										<option value="open">Open</option>
									</FormControl>
								</Col>

								<Button
									onClick={this.handleSaveChanges}
									className="teal-button"
									size="sm"
								>
									Save
								</Button>

								<a
									href={`/admin/submissions?user_id=${_id}`}
									target="_blank"
								>
									<Button
										className="teal-button export-button"
										size="sm"
									>
										View Submissions
									</Button>
								</a>

								{exportPdfButton}

								<Button
									onClick={this.handleShowConfirmDeleteModal}
									size="sm"
									className="delete-button delete-user"
								>
									Delete User
								</Button>
							</Row>
						</Col>
					</Modal.Footer>
				</React.Fragment>
			);
		} else if (userReview.loading) {
			body = this.renderLoading();
		}

		return (
			<Modal
				centered
				className="common-modal submission-modal"
				show={showUserReviewModal}
				onHide={this.handleHideuserReviewModal}
			>
				<Modal.Header closeButton>User Data</Modal.Header>

				{body}
			</Modal>
		);
	};

	renderRiskFields = (field) => {
		const { fieldName, value, assignedScore } = field;

		return (
			<tr key={fieldName}>
				<td>{fieldName}</td>
				<td>{value}</td>
				<td>{assignedScore}</td>
			</tr>
		);
	};

	renderConfirmDeleteModal = () => {
		const { showConfirmDeleteModal, userReview } = this.state;
		let email;
		if (userReview.loaded) {
			email = userReview.data.docs[0].email;
		}

		return (
			<Modal
				centered
				className="common-modal submission-modal"
				show={showConfirmDeleteModal}
				onHide={this.handleHideConfirmDeleteModal}
			>
				<Modal.Header closeButton>User Data</Modal.Header>

				<Modal.Body>
					<Row noGutters className="confirm-delete-modal-row">
						Are you sure you want to delete this user?
					</Row>

					<Row
						noGutters
						className="confirm-delete-modal-row email-row"
					>
						<b>{email}</b>
					</Row>

					<Row noGutters className="confirm-delete-modal-row">
						<Button
							onClick={this.handleDeleteUser}
							className="delete-button"
							size="sm"
						>
							Delete
						</Button>

						<Button
							onClick={this.handleHideConfirmDeleteModal}
							className="teal-button"
							size="sm"
						>
							Cancel
						</Button>
					</Row>
				</Modal.Body>
			</Modal>
		);
	};

	renderResetQueriesButton = () => {
		return (
			<AiOutlineReload
				onClick={this.handleResetQueries}
				className="submissions-reset-queries-button"
			/>
		);
	};

	renderLoading = () => (
		<Row noGutters className="app-spinner">
			<Spinner animation="border" className="common-grey-spinner" />
		</Row>
	);

	renderPagination = () => {
		const { docs, limit, total } = this.state.users.data;
		const totalPages = Math.ceil(total / limit);
		return (
			<Pagination
				totalPages={totalPages}
				handlePageClick={this.handleClickPage}
				dataPageNumber={this.state.currentPage}
			/>
		);
	};

	render() {
		return (
			<Container fluid className="admin-content-container">
				{this.renderReviewUserModal()}
				{this.renderConfirmDeleteModal()}
				{this.renderUserInfoModal()}

				<Row noGutters className="forms-header">
					<h4>User Management</h4>
				</Row>

				<div className="forms-table-box">
					{this.renderSearchBar()}
					{this.renderTable()}
					{this.renderResetQueriesButton()}
					{this.renderPagination()}
				</div>
			</Container>
		);
	}
}

export default UserManagement;
